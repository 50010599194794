import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Input,
  Checkbox,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { CardBrand } from 'components/organisms';
import { truncateString } from 'helpers/helpers-string';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { WindMillLoading } from 'react-loadingg';
import { toTitleCase } from 'helpers/helpers-string';
import { filterKeyInString, filterKeyForEntity } from 'global/global-variables';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
    //minWidth: 300,
    //maxWidth: 600,
  },
}));

const SolutionsFilters = ({
  className,
  filters,
  setFilters,
  filterKey,
  filterData,
  name,
  ...rest
}: SolutionsFiltersProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const [filterArray, setFilterArray] = React.useState<object[]>(filterData);
  const [fKey, setFKey] = React.useState<string>(filterKey);

  const initial_value = filters[filterKey].map(element => {
    if (filterKeyInString.includes(fKey)) {
      return element;
    } else {
      for (var i = 0; i < filterArray.length; i++) {
        if (element === filterArray[i]._id) {
          return filterArray[i].name;
        }
      }
    }
  });

  // Sets the current value of dropdown by replacing ids by names
  const [currentValue, setCurrentValue] = React.useState<string[]>(
    initial_value,
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentValue(event.target.value as string[]);
    if (filterKeyInString.includes(fKey)) {
      var replacedIds = event.target.value;
    } else {
      var replacedIds = event.target.value.map(element => {
        for (var i = 0; i < filterArray.length; i++) {
          if (element === filterArray[i].name) {
            return filterArray[i]._id;
          }
        }
      });
    }
    setFilters({ ...filters, [fKey]: replacedIds });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">{name}</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={currentValue}
          onChange={handleChange}
          input={<Input />}
          renderValue={selected =>
            (selected as string[]).map(item => toTitleCase(item)).join(', ')
          }
          MenuProps={MenuProps}
        >
          {filterData.sort().map(node => (
            <MenuItem key={node._id} value={node.name}>
              <Checkbox checked={currentValue.indexOf(node.name) > -1} />
              <ListItemText primary={toTitleCase(node.name)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SolutionsFilters;
