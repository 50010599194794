import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  useMediaQuery,
  Typography,
  Container,
  IconButton,
  Hidden,
  Box,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';
import { Link } from 'gatsby';
import { CardSolution } from 'components/organisms';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { WindMillLoading } from 'react-loadingg';
import SolutionsGrid from './SolutionsGrid';

const useStyles = makeStyles(theme => ({
  grid: {
    justifyContent: 'center',
  },
  fontWeight900: {
    fontWeight: 900,
  },
  loadingContainer: {
    marginTop: '30px',
    height: '400px',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  categoryIconButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  themeColors: {
    color: theme.palette.primary.light,
  },
  wordHighlight: {
    color: 'orange',
  },
  verticalAlign: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loadMore: {
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'center',
  },
  loadingAnimation: {
    position: 'relative',
  },
}));

const SolutionsShowcase = ({
  className,
  filters,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const [dataPlot, setDataPlot] = useState([]);
  const [currentCursor, setCurrentCursor] = useState(filters.cursor);
  const [nextCursor, setNextCursor] = useState(filters.cursor);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [prevFilter, setPrevFilter] = useState({});
  const [hideLoadMore, setHideLoadMore] = useState(false);
  const [prevData, setPrevData] = useState(null);

  const GETSOLUTIONS = gql`
    query faunaFilterSolutions(
      $sdgs: [String]!
      $solution_types: [String]!
      $industries: [String]!
      $themes: [String]!
      $stakeholders: [String]!
      $value_chain_impact: [String]!
      $cursor: String
    ) {
      filterSolutions(
        sdgs: $sdgs
        solution_types: $solution_types
        industries: $industries
        themes: $themes
        stakeholders: $stakeholders
        value_chain_impact: $value_chain_impact
        _cursor: $cursor
        _size: 18
      ) {
        after
        before
        data {
          _id
          name
          banner_img
          description
          entity {
            data {
              name
              _id
              geographies {
                data {
                  _id
                  name
                }
              }
            }
          }
          sdgs {
            data {
              _id
              name
              sdg_id
              logo_square_img
              color_code_hex
            }
          }
          target_industry {
            data {
              _id
              name
            }
          }
          stakeholders {
            data {
              _id
              name
            }
          }
          themes {
            data {
              _id
              name
            }
          }
          solution_types {
            data {
              _id
              name
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GETSOLUTIONS, {
    variables: {
      sdgs: prevFilter.sdgs,
      solution_types: prevFilter.solution_types,
      industries: prevFilter.industries,
      themes: prevFilter.themes,
      stakeholders: prevFilter.stakeholders,
      value_chain_impact: prevFilter.value_chain_impact,
      cursor: currentCursor,
    },
  });

  useEffect(() => {
    if (filters != prevFilter) {
      setShouldUpdate(true);
      setDataPlot([]);
      setPrevData([]);
      setCurrentCursor(null);
      setNextCursor(null);
      setHideLoadMore(true);
      setPrevFilter(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (data && data.filterSolutions.data != prevData && shouldUpdate) {
      setDataPlot(dataPlot.concat(data.filterSolutions.data));
      setShouldUpdate(false);
      setPrevData(data.filterSolutions.data);
      setCurrentCursor(nextCursor);
      setNextCursor(data.filterSolutions.after);
      if (data.filterSolutions.after === null) {
        setHideLoadMore(true);
      } else {
        setHideLoadMore(false);
      }
    }
  }, [loading, error, data]);

  return (
    <>
      <SolutionsGrid dataPlot={dataPlot} />
      <Grid container spacing={0} justify="center">
        <Grid xs={3} sm={4} />
        <Grid container item xs={6} sm={4} justify="center">
          {loading ? (
            <>
              <div className={classes.loadingContainer}>
                <WindMillLoading
                  color="green"
                  size="large"
                  style={{ position: 'relative', display: 'inline-block' }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {data ? (
            <Hidden xsUp={hideLoadMore}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                component="a"
                onClick={() => {
                  setShouldUpdate(true);
                  setCurrentCursor(nextCursor);
                }}
              >
                Load more
              </Button>
            </Hidden>
          ) : (
            ''
          )}
        </Grid>
        <Grid xs={3} sm={4} />
      </Grid>
    </>
  );
};

export default SolutionsShowcase;
