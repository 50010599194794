import React, { useState, useEffect, useLocation } from 'react';
import { makeStyles, Divider, Grid, Box, Button } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { SolutionsShowcase, SolutionsFilters } from './components';
import { useStaticQuery, graphql } from 'gatsby';
import { DescriptionCta } from 'components/molecules';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  filterGrid: {
    marginBottom: '30px',
  },
}));

const AllSolutions = ({ themeMode, location }): JSX.Element => {
  const classes = useStyles();
  let stateFilters;
  try {
    stateFilters = location.state;
  } catch {
    // Do nothing
  }

  const empty_filter: SolutionFilter = {
    cursor: null,
    sdgs: [],
    solution_types: [],
    industries: [],
    themes: [],
    stakeholders: [],
    value_chain_impact: [],
  };

  const [filters, setFilters] = useState({ ...empty_filter, ...stateFilters });

  const data = useStaticQuery(
    graphql`
      query filterInputs {
        fauna {
          allIndustries(_size: 500) {
            data {
              name
              _id
            }
          }
          allStakeholders(_size: 500) {
            data {
              _id
              name
            }
          }
          allSolutionsTypes(_size: 500) {
            data {
              _id
              name
            }
          }
          allSdgs(_size: 500) {
            data {
              _id
              name
            }
          }
          allThemes(_size: 500) {
            data {
              _id
              name
            }
          }
          allValueChainImpact(_size: 500) {
            data {
              _id
              name
            }
          }
        }
      }
    `,
  );

  useEffect(() => {
    try {
      setFilters = useState({ ...empty_filter, ...location.state });
    } catch {
      // Do nothing
    }
  }, [location]);

  return (
    <div className={classes.root}>
      <Section>
        <Grid container className={classes.filterGrid}>
          <Grid item xs={6} sm={4}>
            <SolutionsFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allSdgs.data}
              name="Development Goals"
              filterKey="sdgs"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SolutionsFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allIndustries.data}
              name="Supported industry"
              filterKey="industries"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SolutionsFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allStakeholders.data}
              name="Stakeholders"
              filterKey="stakeholders"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SolutionsFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allSolutionsTypes.data}
              name="Types of solutions"
              filterKey="solution_types"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SolutionsFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allThemes.data}
              name="Themes"
              filterKey="themes"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SolutionsFilters
              filters={filters}
              setFilters={setFilters}
              filterData={data.fauna.allValueChainImpact.data}
              name="Step of the value chain"
              filterKey="value_chain_impact"
            />
          </Grid>
        </Grid>
        <SolutionsShowcase filters={filters} />
        <Section>
          <Box
            marginBottom={2}
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
            padding={2}
            border="1px solid #ccc"
            borderRadius="4px"
            bgcolor="alternate.main"
          >
            <DescriptionCta
              title={<b>Not seeing a company you know?</b>}
              subtitle="Add it to the database to share it with the Ftprint community."
              primaryCta={
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  href="/contribute-solutions/"
                >
                  Add a solution
                </Button>
              }
              align="left"
            />
          </Box>
        </Section>
      </Section>
    </div>
  );
};

export default AllSolutions;
