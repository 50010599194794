import React from 'react';
import AllSolutions from 'views/AllSolutions';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const AllSolutionsPage = ({ location }): JSX.Element => {
	return (
		<WithLayout
			component={AllSolutions}
			layout={Main}
			location={location}
			key={Date.now()}
		/>
	);
};

export default AllSolutionsPage;
